import { IDropdown } from "../components/formElements/SelectDropdown";

export const addressTypes: IDropdown[] = [
  { name: "Own Home", value: "OWNED" },
  { name: "Rented", value: "RENTED" },
  { name: "Corporate Provided", value: "CORPORATE_PROVIDED" },
  { name: "Relatives House", value: "RELATIVES_HOUSE" },
  { name: "Mortgaged", value: "MORTGAGED" },
];
export const education: IDropdown[] = [
  { name: "12th", value: "higher_secondary" },
  { name: "Graduate", value: "graduate" },
  { name: "Post Graduate", value: "post_graduate" },
];
export const applicationTypes: IDropdown[] = [
  { name: "Personal", value: "PERSONAL" },
  { name: "Business", value: "BUSINESS" },
];
export const customerSegment: IDropdown[] = [
  { name: "Salaried", value: "salaried" },
  { name: "Self Employed", value: "self_employed" },
  { name: "Proprietorship", value: "proprietorship" },
  { name: "Pensioner", value: "pensioner" },
];
export const gender: IDropdown[] = [
  { name: "Male", value: "male" },
  { name: "Female", value: "female" },
];
export const maritalStatus: IDropdown[] = [
  { name: "Single", value: "single" },
  { name: "Married", value: "married" },
  { name: "Divorced", value: "divorced" },
  { name: "Widower", value: "widower" },
];
export const addressProof = {
  acceptedFiles: [
    "Aadhar Card",
    "Passport",
    "LIC Policy",
    "Electoral Roll or Voter ID Card",
    "Ration card",
    "Rental Agreement with affadavit signed by customer",
    "Electricity Bill",
    "Water Bill",
  ],
  infoText:
    "Ensure both the photo and address sides of the proofs are included.",

  minDocuments: 1,
  maxDocuments: 2,
};

export const DocumentTypes = {
  ADDRESS_PROOF: "ADDRESS_PROOF",
};
export const pincodeErrorMessage = "Pincode is not serviceable";
export const JWT_IDENTIFIER = "ask";
export const OEM = {
  Ather: "ATHER",
  Hero: "HERO",
  RTS: "RTS",
};
export const applicationType = {
  LOAN: "LOAN",
  LEASE: "LEASE",
};

export const loanProviders = {
  HFCL: "HFCL",
  IDFC: "IDFC",
};
