import { createSlice } from "@reduxjs/toolkit";
import { applicationStages } from "../../constants/ApplicationStages";

const initialState = {
  isLoading: false,
  applicationStage: applicationStages.APPLICATION_LANDING_PAGE,
  loanApplicationLink:
    "https://staging-portal.autovertplug.com/loan/BLRALHMEWSTRNA?ask=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJvZW1fb3JkZXIiOiIxNzM5NyIsImF1ZCI6Imh0dHA6XC9cL3N0YWdpbmctcG9ydGFsLmF1dG92ZXJ0cGx1Zy5jb21cL2xvYW5cLyIsIm5iZiI6MTY1NDc3NzY5NCwiaWF0IjoxNjU0Nzc3Njk0LCJleHAiOjE2NTczNjk2OTR9.2X7VOsrYzMBWlB5m1Xyruyl-zuDlzqPdLAi0wpQn6hw",
};

export const applicationSliceName = "application";

const applicationSlice = createSlice({
  name: applicationSliceName,
  initialState,
  reducers: {
    setLoading: (state, { payload }: { payload: boolean }) => {
      state.isLoading = payload;
    },
    setApplicationStage: (state, { payload }: { payload: string }) => {
      state.applicationStage = payload;
    },
    setLoanApplicationLink: (state, { payload }: { payload: string }) => {
      state.loanApplicationLink = payload;
    },
  },
});

export const { setApplicationStage, setLoanApplicationLink } =
  applicationSlice.actions;

export default applicationSlice;
