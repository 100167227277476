export const AppRoutes = {
  home: "/",
  pageNotFound: "/404",
  idfclLoanPortal: "/idfc-portal", // temperory
  notFound: "*",
  hfclLoanapplication: "/loan/:autovertId",
  idfcLoanapplication: "/loan/idfc/:autovertId",
  IDFCEMandate: "/idfc/emandate/:autovertId",
  zestLoanapplication: "/loan/zest/:autovertId",
  atherloanCalculator: "/ather-loan-calculator/:currcity/:sku/:token",
  atherloanCalculatorNextPage: "/finance-detail/:token",
  FINANCE_DETAILS: "finance-detail",
  accretiveLoanapplication: "loan/accretive/:autovertId",
  accretiveLinkGeneration:"rts",
  accretiveRTSapplication: "rts/accretive/:autovertId",
  hmclUAT: "/hmcl-uat",
  uploadForm: "upload/:autovertId/:token",
  uploadFormSucess: "sucess/:autovertId",
};
