import { IDropdown } from "../../../components/formElements/SelectDropdown";
export const loanMinAge = 21;
export const loanMaxAge = 60;
export const loanProvider = "IDFC";
export const messageProvider = "Team IDFC";
export const sourceProvider = "source";
export const address = {
  nomineeAddress: "NomineeAddress",
  Address: "Address",
  currentAddress: "Current address",
  permanentAddress: "Permanent Address",
};
export const idfcPanVerificationStatus = {
  SERVICE_DOWN: "SERVICE_DOWN",
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
};
export const UiStatus = {
  ApplicationReview: "APPLICATION_REVIEW",
};
export const ApplicationStatusMessage = {
  documentApproved: "Your Application is approved",
  Pending: "Your Application is under review",
  applicationRejected: "Your Application is Rejected",
};
export const EmadateRedirectingMessage = {
  message: "We are fetching mandate URL and redirecting",
};

export const idfcCustomerAddressType = [
  { name: "Permanent", value: "Permanent" },
  { name: "Rented", value: "Communicaton" },
  { name: "Office Address", value: "Office" },
];

export const idfcApplicationFormStages = {
  ATTACH_BASIC_CUSTOMER_DETAILS: "ATTACH_BASIC_CUSTOMER_DETAILS",
  ATTACH_KYC_DETAILS: "ATTACH_KYC_DETAILS",
  LOAN_ONBOARDING_DETAILS: "LOAN_ONBOARDING_DETAILS ",
  APPLICATION_REVIEW: "APPLICATION_REVIEW",
};

export const idfcCustomerSalutation = [
  { name: "Miss", value: "miss" },
  { name: "Mrs", value: "mrs" },
  { name: "Mr", value: "mr" },
];

export const idfcApplicationFor = [
  { name: "Personal", value: "PERSONAL" },
  { name: "Business", value: "BUSINESS" },
];

export const idfcCustomerSegment = [
  { name: "Govt employee", value: "GOVT." },
  { name: "Private Limited Company", value: "PVT. LTD." },
  { name: "Public Corporation", value: "PUBLIC" },
  { name: "Proprietorship", value: "PROPRIETORSHIP" },
  {
    name: "Partnership",
    value: "PARTNERSHIP",
  },
  { name: "LLP", value: "LIMITED LIABILITY PARTNERSHIP" },
  { name: "Society", value: "SOCIETY" },
];

export const pancard: IDropdown[] = [{ name: "Pancard", value: "pancard" }];
export const aadhar: IDropdown[] = [{ name: "Aadhar", value: "aadhar" }];
export const idfcIdType: IDropdown[] = [
  { name: "Aadhar", value: "aadhar" },
  { name: "Driving License", value: "drivingLicense" },
  { name: "Passport", value: "passport" },
  { name: "Voter ID", value: "voterId" },
  { name: "Ration Card", value: "rationCard" },
];
export const idfcHighestEducation: IDropdown[] = [
  { name: "Post Graduation", value: "POSTGRAD" },
  { name: "Graduation", value: "GRAD" },
  { name: "Higher Secondary", value: "HIGH" },
  { name: "Others", value: "OTHERS" },
];

export const idfcEmploymentType: IDropdown[] = [
  { name: "Salaried", value: "salaried" },
  { name: "Self-Employed", value: "selfemployed" },
  { name: "Retired", value: "retired" },
];

export const idfcgender: IDropdown[] = [
  { name: "Male", value: "male" },
  { name: "Female", value: "female" },
  { name: "Trans Gender", value: "trans" },
];

export const idfcMarrriedStatus: IDropdown[] = [
  { name: "Single", value: "single" },
  { name: "Married", value: "married" },
];

export const relationship: IDropdown[] = [
  { name: "Mother", value: "mother" },
  { name: "Father", value: "Father" },
  { name: "Sister", value: "sister" },
  { name: "Brother", value: "brother" },
  { name: "Son", value: "son" },
  { name: "Daughter", value: "daughter" },
  { name: "Brother In Law", value: "brother_in_law" },
  { name: "Sister In Law", value: "sister_in_law" },
];
export const idProof = {
  aadhar: "aadhar",
  voterID: "voters-id",
};
export const kycProofType = {
  addressProof: "Address proof",
  votersProof: "Voters proof",
  drivingLicenseproof: "Driving license proof",
};

export const idfcaddressProofType = {
  aadhar: "aadhar",
  drivingLicense: "drivingLicense",
  passport: "passport",
  voterId: "voterId",
  rationCard: "rationCard",
};
