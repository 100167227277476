export const orange = "#FF5310";
export const lightBlue = "#00C6D5";
export const boxShadowBlue=" #00D5E51A";
export const boxShadowGrey=" #2828281A";
export const boxShadowGrey1="#28282814";
export const lightGrey = "#f6f6f6";
export const grey = "#777676";
export const white = "#ffffff";
export const darkGrey = "#d2d1d1";
export const lightBlack = "#3e3c3c";
export const black = "#1D1B1B";


