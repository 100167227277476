import { createTheme } from "@mui/material/styles";
import { grey, orange } from "@mui/material/colors";
import { white } from "./colorCodes";
import "../theme/font.css";
import "@mui/material/styles/createPalette";

declare module "@mui/material/styles/createPalette" {
  interface CommonColors {
    red: string;
    black25: string;
    black: string;
    blackCoral: string;
    grey5: string;
    grey10: string;
    grey15: string;
    grey25: string;
    grey50: string;
    grey75: string;
    grey100: string;
    yellow: string;
    otpBackground: string;
  }
}

const atherTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 799,
      md: 800,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: "Neurial Grotesk",

    h1: {
      //fontFamily: "Neurial Grotesk",
      //fontWeight: 400,
      fontSize: "20px",
      lineHeight: "28px",
    },

    h5: {
      fontFamily: "Neurial Grotesk",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.16px",
    },
    h6: {
      fontFamily: "Neurial Grotesk",
      fontWeight: 400,
      fontSize: "10px",
      lineHeight: "14px",
      letterSpacing: "0.16px",
    },
    h2: {
      fontFamily: "Neurial Grotesk Medium",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.32px",
    },
    h4: {
      fontFamily: "Neurial Grotesk",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "14px",
    },
    subtitle1: {
      fontFamily: "Neurial Grotesk",
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: 400,
    },
    //mobile
    subtitle2: {
      fontFamily: "Neurial Grotesk Medium",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.32px",
    },
  },

  palette: {
    primary: {
      main: "#F3F3F3", //silver background
    },
    common: {
      black25: "#31373E",
      black: "#191919",
      grey5: "#F7F7F7",
      grey10: "#F3F3F3",
      grey15: "#E8E8E8",
      grey25: "#D7D7D7",
      grey50: "#B6B6B6",
      grey75: "#7B7B7B",
      grey100: "#5C6470",
      yellow: "#FCD935",
    },
    background: {
      default: white,
    },
  },
});
const hmclTheme = createTheme({
  palette: {
    primary: {
      main: "#00D5E5",
    },
    secondary: {
      main: orange.A700,
    },
    background: {
      default: white,
    },
    common: {
      otpBackground: white,
    },
    grey,
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    h1: {
      fontSize: "2.2rem",
      fontWeight: "bold",
    },
    h3: {
      fontSize: "34px",
      fontWeight: "500",
      color: "#1D1B1B",
      lineHeight: "51px",
    },
    h4: {
      fontSize: "18px",
      fontWeight: "600",
      color: "#1D1B1B",
      lineHeight: "24px",
    },
    h5: {
      fontSize: "18px",
      fontWeight: "400",
      color: "#1D1B1B",
      lineHeight: "24px",
    },
    body2: {
      fontSize: "14px",
      fontWeight: "400",
    },
    body1: {
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "21px",
    },
  },
});
const RTSTheme = createTheme({
  palette: {
    primary: {
      main: "#f58220",
    },
    secondary: {
      main: "#FFFFFF",
    },
    background: {
      default: "#f58220",
    },
    common: {
      otpBackground: "#f58220",
    },

    grey,
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    h1: {
      fontSize: "2.2rem",
      fontWeight: "bold",
      color: "#FFFFFF",
    },
    h3: {
      fontSize: "34px",
      fontWeight: "500",
      color: "#FFFFFF",
      lineHeight: "51px",
    },
    h4: {
      fontSize: "18px",
      fontWeight: "600",
      color: "#FFFFFF",
      lineHeight: "24px",
    },
    h5: {
      fontSize: "18px",
      fontWeight: "400",
      color: "#FFFFFF",
      lineHeight: "24px",
    },
    body2: {
      fontSize: "14px",
      fontWeight: "400",
      color: "#FFFFFF",
    },
    body1: {
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "21px",
      color: "#FFFFFF",
    },
  },

  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiFormLabel-root": {
            fontSize: "16px",
            color: "white",
            height: "20px",
            //fontWeight: "bold",
          },
          "& label.Mui-focused": {
            color: "white",
          },
          "& input": {
            color: "white",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "white",
            borderRadius: "8px",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "white",
              borderRadius: "8px",
            },
            "&:hover fieldset": {
              borderColor: "white",
              borderRadius: "8px",
            },
            "&.Mui-focused fieldset": {
              borderColor: "white",
              borderRadius: "8px",
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          color: "#191919",
          backgroundColor: "#FFFFFF",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        // standardSuccess: {
        //   backgroundColor: "green",
        //   color: "white",
        // },
        // standardError: {
        //   backgroundColor: "red",
        //   color: "white",
        // },
        // standardWarning: {
        //   backgroundColor: "orange",
        //   color: "white",
        // },
        standardInfo: {
          backgroundColor: "#2470b7",
          color: "white",
        },
      },
    },
  },
});
const RTSNewTheme = createTheme({
  palette: {
    primary: {
      main: "#2470b7",
    },
    secondary: {
      main: "#FFFFFF",
    },
    background: {
      default: "#FFFFFF",
    },
    common: {
      otpBackground: "#FFFFFF",
    },

    grey,
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    h1: {
      fontSize: "2.2rem",
      fontWeight: "bold",
      color: "#191919",
    },
    h3: {
      fontSize: "34px",
      fontWeight: "500",
      color: "#191919",
      lineHeight: "51px",
    },
    h4: {
      fontSize: "18px",
      fontWeight: "600",
      color: "#191919",
      lineHeight: "24px",
    },
    h5: {
      fontSize: "18px",
      fontWeight: "400",
      color: "#191919",
      lineHeight: "24px",
    },
    body2: {
      fontSize: "14px",
      fontWeight: "400",
      color: "#191919",
    },
    body1: {
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "21px",
      color: "#191919",
    },
  },

  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& label": {
            marginLeft: -4,
            width: "100%",
            "&.Mui-focused": {
              marginLeft: -4,
              width: "100%",
            },
          },
          "& .MuiInputLabel-root": {
            fontSize: "17px",
            color: "#191919",
            height: "20px",
          },
          "& label.Mui-focused": {
            color: "#191919",
          },
          "& input": {
            color: "#191919",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "#191919",
            borderRadius: "8px",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#191919",
              borderRadius: "8px",
            },
            "&:hover fieldset": {
              borderColor: "#191919",
              borderRadius: "8px",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#191919",
              borderRadius: "8px",
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          color: "#FFFFFF",
          backgroundColor: "#2470b7",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        // standardSuccess: {
        //   backgroundColor: "green",
        //   color: "white",
        // },
        // standardError: {
        //   backgroundColor: "red",
        //   color: "white",
        // },
        // standardWarning: {
        //   backgroundColor: "orange",
        //   color: "white",
        // },
        standardInfo: {
          backgroundColor: "#2470b7",
          color: "#FFFFFF",
        },
      },
    },
  },
});

export default { hmclTheme, atherTheme, RTSTheme, RTSNewTheme };
