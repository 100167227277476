import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { RootStateOrAny, useSelector } from "react-redux";
import Theme from "./theme/theme";
import Router from "./routes/router";
import { toUpper } from "lodash";
import { OEM } from "../src/utils/constants";

const App = () => {
  const { oemName } = useSelector((state: RootStateOrAny) => state.appInfo);
  const brand = toUpper(oemName);
  const getTheme = () => {
    switch (brand) {
      case OEM.Ather:
        return createTheme(Theme.atherTheme);

      case OEM.Hero:
        return createTheme(Theme.hmclTheme);

      case OEM.RTS:
        return createTheme(Theme.RTSNewTheme);

      default:
        return createTheme(Theme.hmclTheme);
    }
  };

  return (
    <ThemeProvider theme={getTheme()}>
      <CssBaseline />
      <Router />
    </ThemeProvider>
  );
};

export default App;
