import { IDropdown } from "../../../../components/formElements/SelectDropdown";
export const loanMinAge = 21;
export const loanMaxAge = 60;

export const kycUploadType = {
  pancard: "pancard",
  addressProof: "addressproof",
};
export const pancard = "PAN";

export const productType = {
  RTS: "RTS",
};

export const minimumDocumentUploads = {
  pan: 1,
  aadhar: 1,
};

export const accretiveDocumentList = {
  aadhaarFront: "aadhaar_front ",
  aadhaarBack: "aadhaar_back",
  panImage: "pan_image",
};

export const panStatus = {
  valid: "VALID",
  invalid: "INVALID",
};

export const currentApplicationStatusLoaderMessage = {
  applicationStatusMessage: "We are processing your application please wait",
};

export const uiStatus = {
  DOCUMENT_SCREEN: "DOCUMENT_SCREEN",
  CALCULATOR_SCREEN: "CALCULATOR_SCREEN",
  DOCUMENT_UPLOAD_SUCESS: "DOCUMENT_UPLOAD_SUCESS",
  APPLICATION_SUCCESS: "APPLICATION_SUCCESS",
  APPLICATION_FAILED: "APPLICATION_FAILED",
  DESICION_PENDING: "DESICION_PENDING",
};

export const fileUpload = {
  adressProofIDTypeError:
    "Please select the Addressproof Id type that you want to upload",
  fileUploadFailureError:
    "The document must be a file of type: jpeg, jpg, png, bmp, pdf.",
};

export const accretiveApplicationStatus = {
  DOCUMENTS_UPLOAD: "DOCUMENTS_UPLOAD",
  DOCUMENTS_APPROVED: "DOCUMENTS_APPROVED",
  APPLICATION_REJECTED: "APPLICATION_REJECTED",
  DOCUMENTS_APPROVAL_PENDING: "DOCUMENTS_APPROVAL_PENDING",
};

export const accretiveApplicationStatusMessage = {
  ACCEPTED: "Congratulation,your application is approved",
  DOCUMENTS_REQUIRED: "Your application is under review.",
  REJECTED: "We regret that the application is rejected.",
  DOCUMENTS_UPLOAD: "Your application is under review.",
};

export const accretiveApplicationStage = {
  ACCEPTED: "APPROVED",
  DOCUMENTS_REQUIRED: "DEVIATED",
  REJECTED: "REJECTED",
};

export const addressType = {
  rented: "RENTED",
  communication: "COMMUNICATION",
  office: "OFFICE",
  permanent: "PERMANENT",
};

export const accretiveRTSApplicationFormStages = {
  OTP_VERIFICATION: "OTP_VERIFICATION",
  CANCEL_OTP_SCREEN: "CANCEL_OTP_SCREEN",
  ATTACH_BASIC_CUSTOMER_DETAILS: "ATTACH_BASIC_CUSTOMER_DETAILS",
  VALIDATE_PAN_AADHAR: "VALIDATE_PAN_AADHAR",
  VALIDATED_PAN_AADHAR: "VALIDATED_PAN_AADHAR",
  CALCULATOR: "CALCULATOR",
  HOUSEOWNER_AADHAR_OTP: "HOUSEOWNER_AADHAR_OTP",
  UPLOAD_SCREEN: "UPLOAD_SCREEN",
  APPLICATION_REVIEW: "APPLICATION_REVIEW",
  DOCUMENT_SUCCESS: "DOCUMENT_SUCCESS",
};

export const accretiveCustomerSegment = [
  { name: "Salaried", value: "SALARIED" },
  { name: "Unemployed", value: "UNEMPLOYED" },
  { name: "Agriculture", value: "AGRICULTURE" },
  { name: "Self Employed Professional", value: "SELF EMPLOYED PROFFESIONAL" },
  { name: "Self Employed Business", value: "SELF EMPLOYED BUSINESS" },
];
export const module = [
  { name: "A", value: "A" },
  { name: "B", value: "B" },
  { name: "C", value: "C" },
  { name: "D", value: "D" },
];
export const inverter = [
  { name: "A", value: "A" },
  { name: "B", value: "B" },
  { name: "C", value: "C" },
  { name: "D", value: "D" },
];
export const tenure = [
  { name: "12", value: "12" },
  { name: "24", value: "24" },
  { name: "36", value: "36" },
  { name: "48", value: "48" },
];
export const accretiveBankAccountType = [
  { name: "Current", value: "CURRENT" },
  { name: "Savings", value: "SAVINGS" },
];

export const accretiveRTSgender: IDropdown[] = [
  { name: "Male", value: "male" },
  { name: "Female", value: "female" },
  { name: "Trans Gender", value: "others" },
];

export const accretiveCustomerAddressType = [
  // { name: "Permanent", value: "Permanent" },
  // { name: "Communication", value: "Communication" },
  // { name: "Office", value: "Office" },
];
export const accretiveCustomerCurrentAddressType = [
  // { name: "Permanent", value: "Permanent" },
  // { name: "Communication", value: "Communication" },
  // { name: "Office", value: "Office" },
];

export const address = {
  nomineeAddress: "NomineeAddress",
  Address: "Address",
  currentAddress: "Current Address",
  permanentAddress: "Permanent Address",
};

export const relationship: IDropdown[] = [
  { name: "Self", value: "self" },
  { name: "Mother", value: "mother" },
  { name: "Father", value: "father" },
  { name: "Sister", value: "sister" },
  { name: "Brother", value: "brother" },
  { name: "Son", value: "son" },
  { name: "Daughter", value: "daughter" },
  { name: "Brother In Law", value: "brother_in_law" },
  { name: "Sister In Law", value: "sister_in_law" },
  { name: "Other", value: "other" },
];

export const accretiveCustomerSalutation = [
  { name: "Miss", value: "miss" },
  { name: "Mrs", value: "mrs" },
  { name: "Mr", value: "mr" },
];

export const accretiveRTSMarrriedStatus: IDropdown[] = [
  { name: "Single", value: "SINGLE" },
  { name: "Married", value: "MARRIED" },
];

export const accretiveHighestEducation: IDropdown[] = [
  { name: "Post Graduation", value: "Post Graduation" },
  { name: "Graduation", value: "Graduation" },
  { name: "Higher Secondary", value: "Higher Secondary" },
  { name: "Others", value: "Others" },
];

export const accretiveEmploymentType: IDropdown[] = [
  { name: "Salaried", value: "salaried" },
  { name: "Self-Employed", value: "selfemployed" },
  // { name: "Retired", value: "retired" },
];
