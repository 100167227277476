import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isProcessingScreen: false,
  applicationStageMessage: "",
  oemName: "Ather",
  sku: "",
  city: "",
};

export const applicationSliceName = "application-info";

const applicationSlice = createSlice({
  name: applicationSliceName,
  initialState,
  reducers: {
    setProcessingScreen: (state) => {
      state.isProcessingScreen = !state.isProcessingScreen;
    },
    setapplicationStageMessage: (state: any, { payload }: { payload: {} }) => {
      state.applicationStageMessage = payload;
    },
    setOemName: (state: any, { payload }: { payload: {} }) => {
      state.oemName = payload;
    },
    setSKU: (state: any, { payload }: { payload: {} }) => {
      state.sku = payload;
    },
    setCity: (state: any, { payload }: { payload: {} }) => {
      state.city = payload;
    },
  },
});

export const {
  setProcessingScreen,
  setapplicationStageMessage,
  setOemName,
  setCity,
  setSKU,
} = applicationSlice.actions;

export default applicationSlice;
