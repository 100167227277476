import { Suspense, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import LinearLoader from "../components/skeletons/LinearLoader";
import { AppRoutes } from "./routes";

const HMCLIndex = lazy(() => import("../pages/hmcl"));
const HFCL = lazy(() => import("../screens/hfcl"));
const IDFCLoanApplication = lazy(() => import("../screens/idfc-loan-portal"));
const IDFCEMandate = lazy(
  () => import("../screens/idfc-loan-portal/IDFCMandateHandler")
);
const ACCRETIVE = lazy(() => import("../screens/accretive/EVLoan"));
const ACCRETIVE_RTS = lazy(() => import("../screens/accretive/RTS"));
const ACCRETIVE_LINK = lazy(() => import("../screens/accretive/RTS/rtsStart"));
const ZEST = lazy(() => import("../screens/zest"));
const ATHERLOANCALCULATOR = lazy(() => import("../screens/ather/calculator"));
const ATHERLOANCALCULATORNEXTPAGE = lazy(
  () => import("../screens/ather/calculator/loan/financeCardDetailPage")
);
const UPLOADFORM = lazy(() => import("../genericUploadForm"));
const UPLOADFORMSUCESS = lazy(
  () => import("../genericUploadForm/applicationReview")
);

const HMCLUAT = lazy(() => import("../screens/hmcl/HMCLUAT"));

const Router = () => {
  return (
    <Suspense fallback={<LinearLoader />}>
      <Routes>
        <Route path={AppRoutes.home} element={<HMCLIndex />} />
        <Route
          path={AppRoutes.idfcLoanapplication}
          element={<IDFCLoanApplication />}
        />
        <Route path={AppRoutes.IDFCEMandate} element={<IDFCEMandate />} />
        <Route path={AppRoutes.hmclUAT} element={<HMCLUAT />} />
        <Route path={AppRoutes.hfclLoanapplication} element={<HFCL />} />
        <Route
          path={AppRoutes.accretiveLoanapplication}
          element={<ACCRETIVE />}
        />
        <Route
          path={AppRoutes.accretiveRTSapplication}
          element={<ACCRETIVE_RTS />}
        />
        <Route
          path={AppRoutes.accretiveLinkGeneration}
          element={<ACCRETIVE_LINK />}
        />
        <Route path={AppRoutes.uploadForm} element={<UPLOADFORM />} />
        <Route
          path={AppRoutes.uploadFormSucess}
          element={<UPLOADFORMSUCESS />}
        />

        <Route path={AppRoutes.zestLoanapplication} element={<ZEST />} />
        <Route
          path={AppRoutes.atherloanCalculator}
          element={<ATHERLOANCALCULATOR />}
        />
        <Route
          path={AppRoutes.atherloanCalculatorNextPage}
          element={<ATHERLOANCALCULATORNEXTPAGE />}
        />
        <Route path={AppRoutes.pageNotFound} element={<p>Page not found</p>} />
        <Route
          path={AppRoutes.notFound}
          element={<Navigate to={AppRoutes.pageNotFound} replace />}
        />
      </Routes>
    </Suspense>
  );
};

export default Router;
