import { createSlice } from "@reduxjs/toolkit";
import { accretiveApplicationFormStages } from "../../../screens/accretive/EVLoan/constants/applicationConstants";
import { accretiveRTSApplicationFormStages } from "../../../screens/accretive/RTS/constants/applicationConstants";

const initialState = {
  isLoading: false,
  applicationStage: accretiveRTSApplicationFormStages.OTP_VERIFICATION,
  aadharData: {},
  RTSapplicationStage: accretiveRTSApplicationFormStages.OTP_VERIFICATION,
  uploadFile: {},
  applicationStatus: {},
  aadhaarDetails: {},
  PANDetails: {},
  LoanDetails: {},
};

export const accretiveApplicationFormSliceName = "accretive-loan-application";

const applicationSlice = createSlice({
  name: accretiveApplicationFormSliceName,
  initialState,
  reducers: {
    setLoading: (state) => {
      state.isLoading = !state.isLoading;
    },
    setAccretiveApplicationFormStage: (
      state,
      { payload }: { payload: string }
    ) => {
      state.applicationStage = payload;
    },
    setAccretiveRTSApplicationFormStage: (
      state,
      { payload }: { payload: string }
    ) => {
      state.RTSapplicationStage = payload;
    },
    setAadharData: (state, { payload }: { payload: string }) => {
      state.aadharData = payload;
    },
    setUploadFile: (state, { payload }: { payload: string }) => {
      state.uploadFile = payload;
    },
    setApplicationStatus: (state, { payload }: { payload: string }) => {
      state.applicationStatus = payload;
    },
    setAadhaarDetails: (state, { payload }: { payload: string }) => {
      state.aadhaarDetails = payload;
    },
    setPANDetails: (state, { payload }: { payload: string }) => {
      state.PANDetails = payload;
    },
    setLoanDetails: (state, { payload }: { payload: string }) => {
      state.LoanDetails = payload;
    },
  },
});

export const {
  setAccretiveApplicationFormStage,
  setAadharData,
  setLoading,
  setAccretiveRTSApplicationFormStage,
  setUploadFile,
  setApplicationStatus,
  setAadhaarDetails,
  setPANDetails,
  setLoanDetails,
} = applicationSlice.actions;

export default applicationSlice;
