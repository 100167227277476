import { IDropdown } from "../../../components/formElements/SelectDropdown";
import { addressProof } from "../../../utils/constants";
export const loanMinAge = 21;
export const loanMaxAge = 60;
export const fileUpload = {
  adressProofIDTypeError:
    "Please select the Addressproof Id type that you want to upload",
  fileUploadFailureError:
    "The document must be a file of type: jpeg, jpg, png, bmp, pdf.",
};

export const pancard = "PAN";
export const loanProvider = "HFCL";
export const messageProvider = "Team HFCL";
export const currentApplicationStatusLoaderMessage = {
  ckycCheckingMessage: "We are processing your request .",
  applicationStatusMessage: "We are processing your application please wait",
};
export const ApplicationUiStatus = {
  applicationCreated: "APPLICATIONCREATED",
  customerBasicDetail: "CUSTOMERBASICDETAIL",
  kycDetail: "KYCDETAIL",
  kycDetailSuccess: "KYCSUCCESS",
  kycUpload: "KYCUPLOAD",
  kycUploadSuccess: "KYCUPLOADSUCCESS",
  loanOnBoarding: "LOANONBOARDING",
  applicationReview: "APPLICATIONREVIEW",
};
export const ApplicationStatus = {
  applicationCreated: "APPLICATION_CREATED",
  documentApproved: "DOCUMENTS_APPROVED",
  mandateRegistrationPending: "EMANDATE_REGISTRATION_PENDING",
  ckycFailed: "CKYC_FAILED",
  CallbacksPending: "CALLBACKS_PENDING",
  applicationRejected: "APPLICATION_REJECTED",
  ckycPending: "CKYC_PENDING",
  documentPending: "DOCUMENTS_APPROVAL_PENDING",
  documentupload: "DOCUMENTS_UPLOAD",
  sanctioned: "SANCTIONED",
  applicationSanction: "APPLICATION_SANCTIONED",
  applicationDisbursalProcessed: "APPLICATION_DISBURSAL_PROCESSED",
  cancelled: "CANCELLED",
};
export const ApplicationStatusMessage = {
  documentApproved: "Your Application is approved",
  Pending: "Your Application is under review.",
  applicationRejected: "We regret that the application is rejected.",
  cancelled: "Your application is cancelled",
  sanction: "Your application is approved and moved for further processing",
};
export const kycUploadType = {
  pancard: "pancard",
  addressProof: "addressproof",
};

export const address = {
  nomineeAddress: "NomineeAddress",
  Address: "Address",
  currentAddress: "Current Address",
  permanentAddress: "Permanent Address",
};
export const hfcladdressProofType = {
  aadhar: "aadhar",
  drivingLicense: "drivingLicense",
  passport: "passport",
  voterId: "voterId",
  rationCard: "rationCard",
};
export const hfclCustomerAddressType = [
  { name: "Permanent", value: "PERMANENT_ADDRESS" },
  { name: "Rented", value: "RENTED" },
];
export const hfclOfficeAddressType = [
  //{ name: "Office", value: "OFFICE" }
];
export const hfclNomineeCustomerAddressType = [
  { name: "Permanent", value: "PERMANENT_ADDRESS" },
  { name: "Rented", value: "RENTED" },
  { name: "Office", value: "OFFICE_ADDRESS" },
];
export const addressType = {
  rented: "RENTED",
  communication: "COMMUNICATION",
  office: "OFFICE_ADDRESS",
  permanent: "PERMANENT",
};
export const gender = {
  Male: "male",
  Female: "female",
};

export const MarrriedStatus = {
  Single: "single",
  Married: "married",
};

export const hfclApplicationFormStages = {
  ATTACH_BASIC_CUSTOMER_DETAILS: "ATTACH_BASIC_CUSTOMER_DETAILS",
  ATTACH_KYC_DETAILS: "ATTACH_KYC_DETAILS",
  UPLOAD_KYC_DOCUMENT: "UPLOAD_KYC_DOCUMENT",
  LOAN_ONBOARDING_DETAILS: "LOAN_ONBOARDING_DETAILS ",
  APPLICATION_REVIEW: "APPLICATION_REVIEW",
};

export const hfclCustomerSalutation = [
  { name: "Miss", value: "miss" },
  { name: "Mrs", value: "mrs" },
  { name: "Mr", value: "mr" },
];

export const hfclApplicationFor = [
  { name: "Personal", value: "PERSONAL" },
  { name: "Business", value: "BUSINESS" },
];

export const hfclCustomerSegment = [
  { name: "Govt employee", value: "GOVT." },
  { name: "Private Limited Company", value: "PVT. LTD." },
  { name: "Public Corporation", value: "PUBLIC" },
  { name: "Proprietorship", value: "PROPRIETORSHIP" },
  {
    name: "Partnership",
    value: "PARTNERSHIP",
  },
  { name: "LLP", value: "LIMITED LIABILITY PARTNERSHIP" },
  { name: "Society", value: "SOCIETY" },
];

export const hfclIdType: IDropdown[] = [
  { name: "Aadhar", value: "aadhar" },
  { name: "Driving License", value: "drivingLicense" },
  { name: "Passport", value: "passport" },
  { name: "Voter ID", value: "voterId" },
  { name: "Ration Card", value: "rationCard" },
];
export const hfclAddressProofUploadType: IDropdown[] = [
  { name: "Aadhaar", value: "AADHAAR" },
  { name: "Driving License", value: "DRIVING_LICENCE" },
  { name: "Passport", value: "PASSPORT" },
  { name: "Voter ID", value: "VOTER_ID" },
  { name: "Ration Card", value: "RATION_CARD" },
];
export const hfclHighestEducation: IDropdown[] = [
  { name: "Post Graduation", value: "POSTGRAD" },
  { name: "Graduation", value: "GRAD" },
  { name: "Higher Secondary", value: "HIGH" },
  { name: "Others", value: "OTHERS" },
];

export const applicationTypes: IDropdown[] = [
  { name: "Personal", value: "personal" },
  { name: "Business", value: "business" },
];

export const hfclEmploymentType: IDropdown[] = [
  { name: "Salaried", value: "salaried" },
  { name: "Self-Employed", value: "selfemployed" },
  { name: "Retired", value: "retired" },
];

export const hfclgender: IDropdown[] = [
  { name: "Male", value: "male" },
  { name: "Female", value: "female" },
  { name: "Trans Gender", value: "trans" },
];

export const hfclMarrriedStatus: IDropdown[] = [
  { name: "Single", value: "single" },
  { name: "Married", value: "married" },
];

export const relationship: IDropdown[] = [
  { name: "Mother", value: "mother" },
  { name: "Father", value: "Father" },
  { name: "Sister", value: "sister" },
  { name: "Brother", value: "brother" },
  { name: "Son", value: "son" },
  { name: "Daughter", value: "daughter" },
  { name: "Brother In Law", value: "brother_in_law" },
  { name: "Sister In Law", value: "sister_in_law" },
  { name: "Spouse", value: "spouse" },
];
