import { createSlice } from "@reduxjs/toolkit";
interface customerState {
  applicationStage: any;
  applicationtype: any;
}
const initialState: customerState = {
  applicationStage: "APPLICATION_CREATED",
  applicationtype: "",
};
export const hfclApplicationStageSlice = createSlice({
  name: "hfclApplicationStage",
  initialState,
  reducers: {
    setHfclApplicationStage: (state: any, { payload }: { payload: {} }) => {
      state.applicationStage = payload;
    },
    setHfclApplicationType: (state: any, { payload }: { payload: {} }) => {
      state.applicationtype = payload;
    },
  },
});

export const { setHfclApplicationStage, setHfclApplicationType } =
  hfclApplicationStageSlice.actions;

export default hfclApplicationStageSlice;
