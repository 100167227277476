import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  financeList: "",
  assets: "",
  availablePlans: "",
  selectedLoanPlans: "",
  selectedfinanceName: "",
  selectedtenure: "",
  selectedAsset: "",
  plans: "",
  gethistory: "",
};

const atherLoanCalculatorSlice = createSlice({
  name: "atherLoanCalculator",
  initialState,
  reducers: {
    setfinanceList: (state: any, { payload }: { payload: {} }) => {
      state.financeList = payload;
    },
    setPlans: (state: any, { payload }: { payload: {} }) => {
      state.plans = payload;
    },
    setAssets: (state: any, { payload }: { payload: {} }) => {
      state.assets = payload;
    },
    setAvailablePlans: (state: any, { payload }: { payload: {} }) => {
      state.availablePlans = payload;
    },
    setSelectedLoanPlan: (state: any, { payload }: { payload: {} }) => {
      state.selectedLoanPlans = payload;
    },
    setFinanceName: (state: any, { payload }: { payload: {} }) => {
      state.selectedfinanceName = payload;
    },
    setSelectedTenure: (state: any, { payload }: { payload: {} }) => {
      state.selectedtenure = payload;
    },
    setSelectedAsset: (state: any, { payload }: { payload: {} }) => {
      state.selectedAsset = payload;
    },
    setHistory: (state: any, { payload }: { payload: {} }) => {
      state.gethistory = payload;
    },
  },
});

export const {
  setfinanceList,
  setAssets,
  setAvailablePlans,
  setSelectedLoanPlan,
  setFinanceName,
  setSelectedTenure,
  setSelectedAsset,
  setPlans,
  setHistory,
} = atherLoanCalculatorSlice.actions;

export default atherLoanCalculatorSlice;
