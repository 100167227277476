import { createSlice } from "@reduxjs/toolkit";
import { hfclApplicationFormStages } from "../../../screens/hfcl/constants/applicationConstants";

const initialState = {
  isLoading: false,
  applicationStage: hfclApplicationFormStages.ATTACH_BASIC_CUSTOMER_DETAILS,
};

export const hfclApplicationFormSliceName = "hfcl-loan-application";

const applicationSlice = createSlice({
  name: hfclApplicationFormSliceName,
  initialState,
  reducers: {
    setLoading: (state) => {
      state.isLoading = !state.isLoading;
    },
    setHFCLApplicationFormStage: (state, { payload }: { payload: string }) => {
      state.applicationStage = payload;
    },
  },
});

export const { setHFCLApplicationFormStage } = applicationSlice.actions;

export default applicationSlice;
