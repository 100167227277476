import { createSlice } from "@reduxjs/toolkit";
import { idfcApplicationFormStages } from "../../../screens/idfc-loan-portal/constants/applicationConstants";

const initialState = {
  isLoading: false,
  applicationStage: idfcApplicationFormStages.ATTACH_BASIC_CUSTOMER_DETAILS,
  panVerificationStatus: "",
};

export const idfcApplicationFormSliceName = "idfc-loan-application";

const applicationSlice = createSlice({
  name: idfcApplicationFormSliceName,
  initialState,
  reducers: {
    setLoading: (state) => {
      state.isLoading = !state.isLoading;
    },
    setIDFCApplicationFormStage: (state, { payload }: { payload: string }) => {
      state.applicationStage = payload;
    },
    setPanVerificationStatus: (state, { payload }: { payload: string }) => {
      state.panVerificationStatus = payload;
    },
  },
});

export const { setIDFCApplicationFormStage, setPanVerificationStatus } =
  applicationSlice.actions;

export default applicationSlice;
