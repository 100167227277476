import { createSlice } from "@reduxjs/toolkit";
interface customerState {
  basicDetails: {};
  kycDetails: {};
}
const initialState: customerState = {
  basicDetails: {},
  kycDetails: {},
};
export const customerBasicDetailSlice = createSlice({
  name: "customerBasicDetail",
  initialState,
  reducers: {
    setCustomerBasicDetails: (state: any, { payload }: { payload: {} }) => {
      state.basicDetails = payload;
    },
    setKYCDetails: (state: any, { payload }: { payload: {} }) => {
      state.kycDetails = payload;
    },
  },
});

export const { setCustomerBasicDetails, setKYCDetails } =
  customerBasicDetailSlice.actions;

export default customerBasicDetailSlice;
