import { createSlice } from "@reduxjs/toolkit";
interface mobileVerfiedState {
  mobileNumberVerified: false;
  mobileNumber: any;
}
const initialState: mobileVerfiedState = {
  mobileNumberVerified: false,
  mobileNumber: "",
};
export const mobileVerificationSlice = createSlice({
  name: "mobileVerificationSlice",
  initialState,
  reducers: {
    setMobileNumberVerified: (state: any, { payload }: { payload: {} }) => {
      state.mobileNumberVerified = payload;
    },
    setMobileNumber: (state: any, { payload }: { payload: {} }) => {
      state.mobileNumber = payload;
    },
  },
});

export const { setMobileNumberVerified, setMobileNumber } =
  mobileVerificationSlice.actions;

export default mobileVerificationSlice;
