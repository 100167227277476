import { configureStore } from "@reduxjs/toolkit";
import applicationReducer from "./slices/applicationSlice";
import hfclApplicationReducer from "./slices/idfcApplicationSlice";
import hfclApplicationFormReducer from "./slices/hfclApplicationFormSlice";
import appInfoReducer from "./slices/appInfoSlice";
import customerBasicDetail from "./slices/customerBasicDetailSlice";
import hfclApplicationStage from "./slices/hfclApplicationStage";
import atherLoanCalulatorReducer from "./slices/atherLoanCalculatorSlice";
import accretiveApplicationFormReducer from "./slices/accretiveFormSlice";
import mobileVerificationReducer from "./slices/mobileVerificationSlice";

export const store = configureStore({
  reducer: {
    application: applicationReducer.reducer,
    idfcApplicationForm: hfclApplicationReducer.reducer,
    hfclApplicationForm: hfclApplicationFormReducer.reducer,
    accretiveApplicationForm: accretiveApplicationFormReducer.reducer,
    appInfo: appInfoReducer.reducer,
    customerBasicDetail: customerBasicDetail.reducer,
    hfclApplicationStage: hfclApplicationStage.reducer,
    atherLoanInfo: atherLoanCalulatorReducer.reducer,
    mobileVerification: mobileVerificationReducer.reducer,
  },
  devTools: process.env.NODE_ENV === "development",
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
